import React, { Component } from 'react';
import { connect } from 'react-redux';
import { searchUsers, loadAgencies } from 'actions';
import { debounce, get } from 'lodash';

import "./index.css";

import Container from 'ui/Container';
import Title, { Filter } from 'ui/Title';
import { Link } from 'react-router-dom';
import List from 'ui/List';



const mapStateToProps = (state, ownProps) => {
  const agencies = Object.keys(state.agencies.all)
    .map(id => ({...state.agencies.all[id], id}));

  const canAdminAll = get(state, "user.permissions.canAdminUsers", false);
  const canAdminAgents = get(state, "user.permissions.canAddAgents", false);
  const canAdminCDs = get(state, "user.permissions.canAddCastingDirectors", false);

  const userAgency = get(state, "user.agencyId", null);

  return {
    isSearching: state.users.isSearching,
    users: state.users.search,
    userAgency,
    agencies,
    canAdminAll,
    canAdminAgents,
    canAdminCDs,
  };
};

const mapDispatchToProps = {
  searchUsers,
  loadAgencies,
};






class Users extends Component {
  state = {
    query: "",
    agencyId: [],
    role: [],
  }

  UNSAFE_componentWillMount() {
    this.props.loadAgencies();
    this.search();
  }

  query = query => {
    this.setState({query}, this.search);
  }

  filterAgency = agencyId => {
    this.setState({agencyId}, this.search);
  }

  filterRole = role => {
    this.setState({role}, this.search);
  }

  search = debounce(() => {
    let { query, agencyId, role } = this.state;

    if(!this.props.canAdminAll) {
      agencyId = [this.props.userAgency];
    }
    if(!this.props.canAdminAll && (!role || !role.length)) {
      role = [];
      if(this.props.canAdminAgents) {
        role.push("agent");
      }
      if(this.props.canAdminCDs) {
        role.push("castingdirector");
      }
    }

    this.props.searchUsers(query, {agencyId, role});
  }, 300)

  render() {
    const { users, agencies, canAdminAll, canAdminAgents, canAdminCDs } = this.props;
    const { query, agencyId, role } = this.state;

    let roles = [];

    if(canAdminAll) {
      roles = [
        {value:'talent', name:'Talent'},
        {value:'agent', name:'Agent'},
        {value:'castingdirector', name:'Casting Director / Producer'}
      ];
    }
    else {
      if(canAdminAgents) {
        roles.push({value:'agent', name:'Agent'});
      }
      if(canAdminCDs) {
        roles.push({value:'castingdirector', name:'Casting Director / Producer'});
      }
    }

    const filters = [];
    if(roles.length > 0) {
      filters.push(
        <Filter
          label="Role"
          value={role}
          options={roles}
          optionValueKey="value"
          optionDisplayKey="name"
          onChange={role => this.filterRole(role)}
          style={{width:100}}
        />
      );
    }

    if(canAdminAll) {
      filters.push(
        <Filter
          label="Agencies"
          value={agencyId}
          options={agencies}
          optionValueKey="id"
          optionDisplayKey="name"
          onChange={agencyId => this.filterAgency(agencyId)}
          style={{width:120}}
        />
      );
    }

    return (
      <Container>

        <Title
          search={query}
          onSearch={query => this.query(query)}
          filters={filters}>
          Users
        </Title>

        <List>
          {Object.keys(users)
            .map(id => users[id])
            .map((user, i) =>
              <Link
                key={user.id}
                to={`/admin/users/${user.id}`}
                className="users-listitem">

                <div className="users-user-name">
                  {user.firstName} {user.lastName}
                </div>
                <div className="users-user-email">
                  {user.email}
                </div>
                <div className="users-user-role">
                  {user.role}
                </div>
              </Link>
            )
          }
        </List>

      </Container>
    );
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Users);