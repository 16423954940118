import React from 'react';

import {
  Text,
  Rule,
  Icon,
  Card,
  Form,
  Button,
  InputText,
  InputSelect,
  InputSwitch,
  InputHTML,
  Modal,
} from 'skybolt-ui';

import ChangePasswordButton from './buttons/ChangePasswordButton';
import ResetPasswordButton from './buttons/ResetPasswordButton';
import LinkGoogleButton from './buttons/LinkGoogleButton';
import {
  useQuery,
  updateUser,
  enableNotifications,
  disableNotifications,
} from 'skybolt-api';

import { includes, without } from 'lodash';


export default function UserAccount(props) {


  // Load all necessary data. If the path contains a
  // userId param then query that user, otherwise the
  // currently logged in user will be used.

  const data = useQuery(`{
    me: user {
      userId
      permissions {
        canAdminTalents
        canAdminUsers
        canAddAgents
        canAddCastingDirectors
      }
      agencyId,
      agency {
        name
      }
    }
    user(userId:$userId) {
      userId,
      fullName
      firstName
      lastName
      email
      useGMail
      googleAuthed
      gmailAddress
      emailUsername
      signature
      permissions
      activeDivision
      role,
      agencyId
      agencyIds
      agency {
        agencyId
        name
        divisions
        requireTalentSubscription
        requireUserSubscription
      }
      agencies {
        name
      }
    }
    agencies {
      agencyId
      name
    }
    notificationsEnabled
  }`, {userId:props.match.params.userId});



  // When a form is changed.

  const handleChange = (_, change) => {
    // TODO: we shouldn't have to do this.
    for (var propName in change) {
      if (change[propName] === undefined) {
        change[propName] = null;
      }
    }
    if(change.emailUsername) {
      change.emailUsername = change.emailUsername.toLowerCase() || null;
    }
    updateUser({
      userId: data.user.userId,
      change: change,
    });
  };



  // Completely remove this user from the logged in user's agency.

  const removeFromAgency = async () => {

    const conf = await Modal.confirm(`Are you sure you want to remove ${data.user.fullName} from ${data.me.agency.name}?`);
    if(!conf) {
      return;
    }

    let agencyId = data.user.agencyId;
    let agencyIds = data.user.agencyIds;

    if(agencyId === data.me.agencyId) {
      agencyId = null;
    }
    if(includes(agencyIds, data.me.agencyId)) {
      agencyIds = without(agencyIds, data.me.agencyId);
    }

    updateUser({
      userId: data.user.userId,
      update: {
        agencyId: agencyId || null,
        agencyIds: agencyIds || null,
      },
    });
  };



  // Layout

  if(!data) {
    return (
      <div style={{textAlign:'center'}}>
        <Icon name="loading" large/>
      </div>
    );
  }

  return (
    <div style={{marginBottom:40}}>
      <Form value={data.user} onChange={handleChange}>

        {/* Account */}

        <div style={{display:'flex', alignItems:'flex-end', height:40}}>
          <Text title>ACCOUNT</Text>
        </div>
        <Rule style={{marginBottom:24}}/>

        <Card style={{padding:"16px 16px 32px 16px"}}>
          <div style={{maxWidth:600}}>

            {!!data.me.permissions.canAdminUsers ? (
              <InputText title="Email Address" placeholder="john@example.com" name="email"/>
            ) : (
              <div>
                <Text small block style={{paddingBottom:2}}>Email Address</Text>
                <Text paragraph style={{fontSize:16}}>{data.user.email}</Text>
              </div>
            )}

            <div style={{display:'flex', flexWrap:'wrap'}}>
              <InputText title="First Name" name="firstName" style={{flex:1, minWidth:200, marginRight:16}}/>
              <InputText title="Last Name" name="lastName" style={{flex:1, minWidth:200}}/>
            </div>

            <div style={{display:'flex', flexWrap:'wrap', marginBottom:8}}>
              {data.me.userId === data.user.userId && <ChangePasswordButton style={{marginRight:16, marginBottom:8}}/>}
              {data.me.permissions.canAdminTalents && <ResetPasswordButton email={data.user.email} style={{marginBottom:8}}/>}
            </div>

            {!!data.user.permissions.canPack && data.user.agency.divisions &&
              <InputSelect
                title="Active Division"
                name="activeDivision"
                strict
                clearable
                options={data.user.agency.divisions}
                hint="Limit talent searches to this division."
              />
            }

            {!!data.me.permissions.canAdminUsers && !!data.user.permissions.canOwnTalents && data.user.agency.divisions &&
              <InputSelect
                title="Talent Divisions"
                name="divisions"
                strict
                multiple
                clearable
                options={data.user.agency.divisions}
                hint="New talent accounts are created in these divisions."
              />
            }

            {!!data.me.permissions.canAdminUsers &&
              <InputSelect
                name="role"
                title="Role"
                clearable
                options={[
                  {label: "Talent", value: "talent"},
                  {label: "Agent", value: "agent"},
                  {label: "Guest", value: "castingdirector"},
                ]}
              />
            }

            {!!data.me.permissions.canAdminUsers &&
              <InputSelect
                name="agencyId"
                title="Primary Agency"
                clearable
                hint={<span>
                  Give {data.user.fullName} primary access to this agency. Exact
                  permissions can be set below.
                </span>}
                options={data.agencies.map(a => ({label:a.name, value:a.agencyId}))}
              />
            }

            {!!data.me.permissions.canAdminUsers &&
              <InputSelect
                name="agencyIds"
                title="Guest Agencies"
                multiple
                clearable
                hint={<span>
                  Give {data.user.fullName} guest access to these agencies. Exact
                  permission can be set below.
                </span>}
                options={data.agencies.map(a => ({label:a.name, value:a.agencyId}))}
              />
            }

            {
            // If we can add users to this agency, give them a
            // button to fully remove them.

            (data.me.permissions.canAdminUsers ||
            data.me.permissions.canAddTalents ||
            data.me.permissions.canAddAgents ||
            data.me.permissions.canAddCastingDirectors) && (
              <Button
                raised
                label="Remove from Agency"
                onClick={removeFromAgency}
                style={{marginBottom:16}}
              />
            )}

          </div>
        </Card>


        {/* Gmail Integration */}

        {data.user.permissions.canGMail &&
          <div>
            <div style={{display:'flex', alignItems:'flex-end', height:40, marginTop:32}}>
              <Text title>GMAIL</Text>
            </div>
            <Rule style={{marginBottom:24}}/>
            <Card style={{padding:"16px 16px 24px 16px"}}>

              <InputSwitch
                large title="Integrate Gmail"
                hint="Linking your Gmail will cause Skybolt to send all email from your Gmail address. Skybolt will monitor your Gmail and import messages where applicable."
                name="useGMail"
              />

              {(!!data.user.useGMail && !!data.user.googleAuthed && !!data.user.gmailAddress) &&
                <div>
                  <Text>Using {data.user.gmailAddress}</Text>
                </div>
              }

              {(!!data.user.useGMail && (!data.user.googleAuthed || !data.user.gmailAddress)) &&
                <LinkGoogleButton userId={data.user.userId}/>
              }
            </Card>
          </div>
        }



        {/* Message Settings */}

        {data.user.permissions.canCustomEmail &&
          <div>
            <div style={{display:'flex', alignItems:'flex-end', height:40, marginTop:32}}>
              <Text title>MESSAGE SETTINGS</Text>
            </div>
            <Rule style={{marginBottom:24}}/>
            <Card style={{padding:"16px 16px 16px 16px"}}>
              <div style={{maxWidth:600}}>
                <InputText
                  name="emailUsername"
                  title="Email Address"
                  hint="Choose a custom @mail.skybolt.net email address."
                />
                <InputHTML
                  name="signature"
                  title="Email Signature"
                  hint="Attach a signature to emails sent from Skybolt."
                />
              </div>
            </Card>
          </div>
        }


        {/* Notification Settings */}

        <div style={{display:'flex', alignItems:'flex-end', height:40, marginTop:32}}>
          <Text title>NOTIFICATIONS</Text>
        </div>
        <Rule style={{marginBottom:24}}/>
        <Card style={{padding:"16px 16px 16px 16px"}}>
          <InputSwitch
            disabled={data.user.userId !== data.me.userId}
            large
            value={data.notificationsEnabled}
            onChange={enabled => !!enabled ? disableNotifications() : enableNotifications()}
            title="Enable Push Notifications"
            hint="Get notifications pushed to your browser when a new message is received."
          />
        </Card>



        {/* Permissions */}

        {data.me.permissions.canAdminUsers &&
          <div>
            <div style={{display:'flex', alignItems:'flex-end', height:40, marginTop:32}}>
              <Text title>PERMISSIONS</Text>
            </div>
            <Rule style={{marginBottom:24}}/>

            <Card style={{padding:"16px 16px 0 16px"}}>

              <InputSwitch
                name="permissions.canOwnTalents"
                large
                title="Own talents"
                hint={`
                  Give ${data.user.fullName} the permission to "own" talents. Users
                  with this permission are given access to the Talents section and
                  can create talents. ${data.user.firstName} will be billed for those
                  talents according to their agency settings.
                `}
              />
              <InputSwitch
                name="permissions.canRequestPacks"
                large
                title="Request packages"
                hint={`
                  Allow ${data.user.fullName} to create package requests and send them
                  to outside agencies.
                `}
              />
              <InputSwitch
                name="permissions.canPack"
                large
                title="Create and manage packages"
                hint={`
                  Enable the packaging system for ${data.user.fullName}. This will
                  give ${data.user.firstName} permission to create packages, add
                  talent to packages, modify the package shortlist, and create and
                  modify schedules.
                `}
              />
              <InputSwitch
                name="permissions.canViewPacks"
                large
                title="View package history"
                hint={<div>
                  Allow {data.user.fullName} to view packages they have been involved in.
                  This is applicable to casting directors and producers to view
                  packages they have been sent. The <strong>Create and mange
                  packages</strong> permission automatically enables this.
                </div>}
              />
              <InputSwitch
                name="permissions.canViewTalents"
                large
                title="View guest agency talent"
                hint={<div>
                  Allow {data.user.fullName} to view talent in agencies where they are
                  a guest user. This is mostly reserved for Casting Directors, Producers,
                  or other guest users.
                </div>}
              />
              <InputSwitch
                name="permissions.canMessageTalents"
                large
                title="Message talent"
                hint={<div>
                  Allow {data.user.fullName} to message talent directly, and view
                  messages in talent message histories.
                </div>}
              />
              <InputSwitch
                name="permissions.canNoteTalents"
                large
                title="View and edit talent notes"
                hint={<div>
                  Give {data.user.fullName} the permission to view and edit talent
                  notes. Generally, this will be true for agents, and false for talent
                  and guest users.
                </div>}
              />
              <InputSwitch
                name="permissions.canEditTalents"
                large
                title="Edit talent"
                hint={<div>
                  Allow {data.user.fullName} edit talent information in their agency.
                </div>}
              />
              <InputSwitch
                name="permissions.canApproveMedia"
                large
                title="Approve media"
                hint={<div>
                  Give {data.user.fullName} permission to approve new media uploaded
                  by talents. This only applies if this user's agency requires
                  new talent media to be approved.
                </div>}
              />
              <InputSwitch
                name="permissions.canApproveTalents"
                large
                title="Approve talent"
                hint={<div>
                  Give {data.user.fullName} permission to approve new talent who
                  have requested to join this user's agency. This only applies if
                  this user's agency requires new talent to be approved.
                </div>}
              />
              <InputSwitch
                name="permissions.canConfigAgency"
                large
                title="Config. agency"
                hint={<div>
                  Give {data.user.fullName} access to {data.user.agency.name} configuration.
                  They will be able to configure the agency's information,
                  display settings, divisions and tags, talent fields, canned messages
                  and system settings.
                </div>}
              />
              <InputSwitch
                name="permissions.canSpotlight"
                large
                title="Create and modify spotlights"
                hint={<div>
                  Give {data.user.fullName} permission create and edit spotlights
                  in {data.user.agency.name}.
                </div>}
              />
              <InputSwitch
                name="permissions.canAddTalents"
                large
                title="Invite talent"
                hint={<div>
                  Give {data.user.fullName} permission to add talent to
                  {data.user.agency.name}.
                </div>}
              />
              <InputSwitch
                name="permissions.canAddAgents"
                large
                title="Invite agents"
                hint={<div>
                  Give {data.user.fullName} permission to add agents to
                  {data.user.agency.name}.
                </div>}
              />
              <InputSwitch
                name="permissions.canAddCastingDirectors"
                large
                title="Invite guests"
                hint={<div>
                  Give {data.user.fullName} permission to add guests to
                  {data.user.agency.name}.
                </div>}
              />
              <InputSwitch
                name="permissions.canAgencyCalendar"
                large
                title="View agency calendar"
                hint={<div>
                  Give {data.user.fullName} the permission to see and update an agency's calendar.
                  Generally, this will be true for agents, and false for talents and
                  casting directors.
                </div>}
              />
              <InputSwitch
                name="permissions.canAdminUsers"
                large
                title="Admin users"
                hint={<div>
                  Give {data.user.fullName} permission to view and edit other users
                  and to edit those users' permissions. This applies to users
                  across all Skybolt agencies. Generally only for system administrators.
                </div>}
              />
              <InputSwitch
                name="permissions.canAdminAgencies"
                large
                title="Admin agencies"
                hint={<div>
                  Give {data.user.fullName} permission to view and edit agencies other than
                  their own and to edit those agencies' settings. Generally only
                  for system administrators.
                </div>}
              />
              <InputSwitch
                name="permissions.canAdminTalents"
                large
                title="Admin talents"
                hint={<span>
                  Give {data.user.fullName} permission to view and edit talents outside of
                  their agency. Generally only for system administrators.
                </span>}
              />
              <InputSwitch
                name="permissions.canAdminFAQ"
                large
                title="Admin FAQs"
                hint={<span>
                  Give {data.user.fullName} permission edit FAQ content.
                </span>}
              />
              <InputSwitch
                name="permissions.canAdminApp"
                large
                title="Admin application"
                hint={<span>
                  Give {data.user.fullName} permission to view app administration functions.
                </span>}
              />
              <InputSwitch
                name="permissions.canCustomEmail"
                large
                title="Customize Skybolt email address"
                hint={<span>
                  Give {data.user.fullName} permission specify a custom email
                  address for email sent via Skybolt.
                </span>}
              />
              <InputSwitch
                name="permissions.canGMail"
                large
                title="Integrate Gmail"
                hint={<span>
                  Give {data.user.fullName} permission to integrate their Gmail
                  account for email messaging.
                </span>}
              />
              <InputSwitch
                name="permissions.canCCSelf"
                large
                title="CC self in outgoing emails"
                hint={<span>
                  Give {data.user.fullName} permission to CC their user email
                  address in outgoing emails.
                </span>}
              />
              <InputSwitch
                name="permissions.canSeeSeekingAgencyTalent"
                large
                title="See talent seeking representation"
                hint={<span>
                  Give {data.user.fullName} permission to view talent seeking representation.
                </span>}
              />
              <InputSwitch
                name="permissions.canAdminBilling"
                large
                title="Admin all agency's billing"
                hint={<span>
                  Give {data.user.fullName} permission to view and edit billing
                  features for all agencies and access additional settings.
                </span>}
              />
              <InputSwitch
                name="permissions.canAgencyBilling"
                large
                title="Manage agency billing"
                hint={<span>
                  Give {data.user.fullName} permission to view and edit billing
                  features in {data.user.agency.name}.
                </span>}
              />
              <InputSwitch
                name="permissions.canAgencyBillingReports"
                large
                title="View agency billing reports"
                hint={<span>
                  Give {data.user.fullName} permission filter and download billing history
                  in {data.user.agency.name}.
                </span>}
              />

            </Card>

          </div>
        }


      </Form>
    </div>
  );
}