import React, { useContext, useState } from 'react';
import { Text, Rule, useRouter, ThemeContext, Icon, Currency, Drawer, Button, Card, Loading } from 'skybolt-ui';
import { useQuery } from 'skybolt-api';
import moment from 'moment';
import InputPackage from './InputPackage.js';
import InputClient from './InputClient.js'
import DebitLI from './debit-li.js';
import DebitsHeader from './debits-header.js';


export default function() {

  const route = useRouter();
  let talentId;
  try {
    talentId = route.match.params.talentId;
  }
  catch(err) {
  }

  const theme = useContext(ThemeContext);
  const [debitsLimit, setDebitsLimit] = useState(30);
  const [filterByPack, setPackFilter] = useState(null);
  const [filterByClient, setClientFilter] = useState(null);
  const [showDebits, setShowDebits] = useState(false);

  const data = useQuery(`{
    talent(talentId:$talentId) {
      agency {
        agencyId
        billingAddress1
      }
    }
    pendingDebits: debits(talentId:$talentId, status:"PENDING") 
    draftDebits: debits(talentId:$talentId, status:"DRAFT")
    checks(talentId:$talentId, orderBy:"date") {
      checkId
      number
      date
      status
      debitIds
      debits {
        debitId
        packName
        invoiceNumber
        dateJob
        packName
        dateMaxUse
        clientName
        grossAmount
        taxAmount
        otherAmount
        agencyAmount
        amount
        status
      }
      amount
    }
    debits(packId:$packId, clientId:$clientId, talentId:$talentId, status:"SENT", orderBy:"dateJob", limit:$debitsLimit)
    user {
      permissions {
        canAgencyBilling
        canAdminAgencies
      }
    }
  }`, {talentId, packId:filterByPack?.packId, clientId:filterByClient?.clientId, debitsLimit});


  // Handle expanding check debits.

  const [expanded, setExpanded] = useState([]);
  const isExpanded = check => expanded.indexOf(check.checkId) > -1;

  const toggleExpanded = check => {
    if(isExpanded(check)) {
      setExpanded(expanded.filter(c => c !== check.checkId));
    }
    else {
      setExpanded([...expanded, check.checkId]);
    }
  };

  if(!data) {
    return <Loading/>;
  }
  
  const canDownload1099 = !!data.talent?.agency?.billingAddress1;

  return (
    <div>

      {Array.isArray(data.pendingDebits) && data.pendingDebits.length > 0 && (
        <div>
          <div style={{marginTop:24, marginBottom:24, height:40}}>
            <Text title block>PENDING PAYMENTS</Text>
            <Rule/>
          </div>
          <div style={{width:'100%', overflowX:'auto'}}>
            <DebitsHeader/>
            {data.pendingDebits.map(debit => 
              <DebitLI debit={debit} key={debit.debitId}/>
            )}
          </div>
        </div>
      )}

      {Array.isArray(data.draftDebits) && data.draftDebits.length > 0 && (
        <div>
          <div style={{marginTop:24, marginBottom:24, height:40}}>
            <Text title block>SCHEDULED PAYMENTS</Text>
            <Rule/>
          </div>
          <div style={{width:'100%', overflowX:'auto'}}>
            <DebitsHeader/>
            {data.draftDebits.map(debit => 
              <DebitLI debit={debit} key={debit.debitId}/>
            )}
          </div>
        </div>
      )}


      <div style={{marginTop:24, marginBottom:24}}>
        <div style={{display:'flex', alignItems:'flex-end'}}>
          <Text title block>PAYMENT HISTORY</Text>
          <div style={{flex:1}}/>
          {data.user.permissions.canAgencyBilling && 
            <InputPackage 
              value={filterByPack || {}}
              placeholder="Package..."
              onChange={p => setPackFilter(p)}
            />
          }
          {data.user.permissions.canAgencyBilling && 
            <InputClient
              client={filterByClient || {}}
              placeholder="Client..."
              onChange={c => setClientFilter(c)}
            />
          }
          {canDownload1099 &&
            <Button raised accent onClick={() => window.open(`https://us-central1-skybolt-99adb.cloudfunctions.net/billingReport1099?talent=${talentId}`)}>
              <i className="fa-solid fa-file-arrow-down"></i> &nbsp; Download 1099
            </Button>
          }
        </div>
        <Rule/>
        {data.user.permissions.canAgencyBilling && 
          <div>
            <Button
              label="Checks"
              onClick={() => setShowDebits(false)}
              style={{
                borderRadius: 0,
                backgroundColor: !showDebits ? theme.color.primaryLighter : theme.color.greyLight
              }}
            />
            <Button
              label="All Payments"
              onClick={() => setShowDebits(true)}
              style={{
                borderRadius: 0,
                backgroundColor: showDebits ? theme.color.primaryLighter : theme.color.greyLight
              }}
            />
          </div>
        }
      </div>


      {(showDebits || filterByPack?.packId || filterByClient?.clientId) && (
        <div style={{width:'100%', overflowX:'auto'}}>
          <DebitsHeader/> 
          {data.debits.map(debit => 
            <DebitLI debit={debit} key={debit.debitId}/>
          )}

          {data.debits.length == debitsLimit && 
            <nav style={{textAlign:'center'}}>
              <Button onClick={() => setDebitsLimit(debitsLimit + 30)}>Load More</Button>
            </nav>
          }
        </div>
      )}

      {!showDebits && !filterByPack?.packId && !filterByClient?.clientId && data.checks.filter(check => check.status != "VOID").map(check =>
        <div key={check.checkId} style={{display:'flex', alignItems:'flex-start'}}>
          <Card style={{flex:2, marginBottom:4}}>

            <div style={{display:'flex', alignItems:'center', height:40, backgroundColor:'white'}}>
              <div style={{width:100, paddingLeft:16}}>
                <Text>{moment(check.date).format("MM/DD/YYYY")}</Text>
              </div>
              <div style={{width:100}}>
                <Text>#{check.number}</Text>
              </div>
              <div style={{flex:2}}/>
              <div style={{width:100}}>
                <Text bold>{check.status}</Text>
              </div>
              <Button onClick={()=>toggleExpanded(check)} style={{width:150, alignSelf:'stretch', display:'flex', alignItems:'center', justifyContent:'flex-start', padding:0, paddingLeft:8}}>
                <Text>{check.debitIds.length} {check.debitIds.length === 1 ? "payment" : "payments"} <Icon name="caret-down" small color="textLight" style={{marginLeft:4}}/></Text>
              </Button>
              <div style={{width:150}}>
                <Currency bold>{check.amount}</Currency>
              </div>
            </div>


            {/* Drawer reveals linked debits. */}

            <Drawer open={isExpanded(check)}>
              <div style={{padding:16}}>
                <div style={{display:'flex'}}>
                  <div style={{flex:2}}>
                    <Text small light>Job Date</Text>
                  </div>
                  <div style={{flex:2}}>
                    <Text small light>Package</Text>
                  </div>
                  <div style={{flex:2}}>
                    <Text small light>Client</Text>
                  </div>
                  <div style={{flex:1}}>
                    <Text small light>Invoice #</Text>
                  </div>
                  <div style={{flex:1}}>
                    <Text small light>Gross</Text>
                  </div>
                  <div style={{flex:1}}>
                    <Text small light>Tax</Text>
                  </div>
                  <div style={{flex:1}}>
                    <Text small light>Other</Text>
                  </div>
                  <div style={{flex:1}}>
                    <Text small light>Agency</Text>
                  </div>
                  <div style={{flex:1}}>
                    <Text small light>Net</Text>
                  </div>
                </div>
                <Rule style={{margin:"0 0 8px 0"}} color={theme.color.greyLight}/>
                {(check.debits || []).map(debit =>
                  <div key={debit.debitId} style={{minHeight:40, display:'flex', alignItems:'center'}}>
                    <div style={{flex:1, display:'flex', minHeight:40, padding:0}}>
                      <div style={{flex:2}}>
                        <Text>{debit.dateJob ? moment(debit.dateJob).format("MM/DD/YYYY") : ""}</Text>
                      </div>
                      <div style={{flex:2, overflow:'hidden'}}>
                        <Text block style={{whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis'}}>
                          {debit.packName || ""}
                        </Text>
                      </div>
                      <div style={{flex:2, overflow:'hidden'}}>
                        <Text block style={{whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis'}}>
                          {debit.clientName || ""}
                        </Text>
                      </div>
                      <div style={{flex:1}}>
                        <Text>{debit.invoiceNumber || ""}</Text>
                      </div>
                      <div style={{flex:1}}>
                        <Currency>{debit.grossAmount}</Currency>
                      </div>
                      <div style={{flex:1}}>
                        <Currency>{debit.taxAmount}</Currency>
                      </div>
                      <div style={{flex:1}}>
                        <Currency>{debit.otherAmount}</Currency>
                      </div>
                      <div style={{flex:1}}>
                        <Currency>{debit.agencyAmount}</Currency>
                      </div>
                      <div style={{flex:1}}>
                        <Currency>{debit.amount}</Currency>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Drawer>

          </Card>
        </div>
      )}
    </div>
  );
}