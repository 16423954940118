/*

# Talent Media

If you provide a `cols` property, the media grid will respect that,
otherwise it will collapse on a responsive layout. Inject the prop
if the view isn't intended to go full width; when it's in a dialog.

*/



import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import { connect } from 'react-redux';
import { mediaFormats, imageFormats } from 'config';
import {
  loadTalentMedia,
  addMedia,
  uploadMedia,
  removeMedia,
  moveTalentMedia,
} from 'actions';
import { isEmpty, get as getProperty } from 'lodash';
import './index.css';

import Title, { Action, Filter, Tab } from 'ui/Title';
import Placeholder from 'ui/Placeholder';
import Loading from 'ui/Loading';
import MediaItem from './MediaItem';
import MediaDialog from './MediaDialog';
import {
  SortableContainer,
  SortableElement,
} from 'react-sortable-hoc';

function isTouchDevice() {
  return 'ontouchstart' in document.documentElement;
}

function hasClass(element, name) {
  return (' ' + element.className + ' ').indexOf(' ' + name + ' ') > -1;
}

function getScrollParent(node) {
  if (node == null) {
    return null;
  }

  if(node.scrollHeight > node.clientHeight) {
    return node;
  }

  if(hasClass(node, 'scroll-container')) {
    return node;
  }

  return getScrollParent(node.parentNode);
}

const SortableItem = SortableElement(props => {
  return <MediaItem {...props}/>;
});

class SortableListComponent extends Component {
  render() {
    const {cols, children} = this.props;
    return (
      <div
        className="talent-media-grid"
        style={{gridTemplateColumns:`repeat(${cols}, 1fr)`}}>
        {children}
      </div>
    );
  }
}

const SortableList = SortableContainer(SortableListComponent, {withRef:true});

export const mapStateToProps = (state, ownProps) => {
  const talentId = ownProps.match.params.talentId;
  const packId = !!ownProps.location.state && ownProps.location.state.packId;

  const userId = state.user.id;
  const user = state.users.all[userId];
  const imageOrder = !!user ? user.role || 'talent' : 'agent';
  const talent = state.talents.all[talentId];
  const packTalents = (packId && state.packTalents.byPack[packId]) || {};

  const isLoggedIn = state.user.isLoggedIn;
  const isOwnTalent = isLoggedIn && !!user && !!user.talents && !!user.talents[talentId];
  const canEdit = isOwnTalent || (isLoggedIn && talent?.agencyId === user?.agencyId && user?.permissions?.canEditTalents) || (isLoggedIn && user?.permissions?.canAdminTalents);
  
  const agencyId = talent.agencyId;
  const agency = state.agencies.all[agencyId] || {};
  
  const isInPack =
    // there is a talent and a pack
    !!packId && !!talent &&

    // this talent is in the pack
    Object.keys(packTalents).indexOf(talent.id) !== -1;

  let media;
  if(canEdit) {
    media = state.media.byTalent[talentId] || {};
  }
  else if(isInPack) {
    media = packTalents[talent.id].media || {};
  }
  else {
    media = {};
    Object.keys(state.media.byTalent[talentId] || {}).forEach(itemId => {
      const item = state.media.byTalent[talentId][itemId];
      if(!!item && item.approved && item.active) {
        media[itemId] = item;
      }
    });
  }

  return {
    userId,
    imageOrder,
    talentId,
    talent,
    canEdit,
    isPaid: talent.status === 'PAID' || talent.status === 'INACTIVE',
    isLoggedIn,
    isOwnTalent,
    media,
    agency,
    user,
    ...ownProps,
  };
};

export const mapDispatchToProps = {
  loadTalentMedia,
  addMedia,
  uploadMedia,
  removeMedia,
  moveTalentMedia,
};

const mediaSizes = {
  "Thumbnail": 200,
  "Small": 400,
  "Large": 800
};

export class TalentMedia extends Component {
  state = {
    isEditing: false,
    selected: null,
    statuses: [],
    filters: [],
    division: false,
    tags: [],
    size: 'Small',
    cols: 1,
    isImageEnabled: true,
    isVideoEnabled: true,
  }


  componentWillUnmount() {
    window.removeEventListener('resize', this.checkSize);
  }

  async componentDidMount() {

    this.props.loadTalentMedia(this.props.talentId);
    window.addEventListener('resize', this.checkSize);
    this.checkSize();

    if(this.props.user?.activeDivision && this.props.agency.filterTalentMediaByDivision) {
      this.setState({
        division: this.props.user.activeDivision,
      });
    }

    if(this.props.talent.canMedia) {
      this.setState({
        isImageEnabled: !!this.props.talent.canMedia,
        isVideoEnabled: this.props.talent.canVideo !== false,
      });
      return;
    }

    if(this.props?.agency?.requireTalentSubscription && this.props?.talent?.userId) {

      // If the user has an epay account, set isVideoEnabled and isImageEnabled to true,      
      // If the user has a stripe subscription with enable video and image accordingly.

      let sub = await window.skyboltsnow.subscription(this.props.talent.userId);
      let unsubscribe = sub.subscribe(subscription => {
        if(subscription.isLoading) {
          return;
        }
        if(!subscription.id && this.props.isPaid) {
          this.setState({
            isVideoEnabled: true,
            isImageEnabled: true,
          });
          return;
        }

        let talent = subscription.talents?.[this.props.talentId];
        let allTalent = subscription.talents?.['ALL'];
        let isVideoEnabled = talent?.features?.includes('video') || allTalent?.features?.includes('video');
        let isImageEnabled = talent?.features?.includes('image') || allTalent?.features?.includes('image');

        this.setState({
          isVideoEnabled,
          isImageEnabled,
        });
      })
      return unsubscribe;
    }

  }

  setDivision = division => {
    this.setState({division});
  }

  handleFileSelect = async files => {
    for(var i=0; i<files.length; i+=1) {
      if(files[i].type.indexOf('video') > -1 && this.state.isVideoEnabled === false) {
        continue;
      }
      const media = await this.props.addMedia(this.props.talentId, {isLoading:true});
      this.props.uploadMedia(media.id, files[i]);
    }
  }

  checkSize = () => {
    if(!this.el) {
      return;
    }
    const cols = Math.ceil(this.el.scrollWidth / mediaSizes[this.state.size]);
    if(cols !== this.state.cols) {
      this.setState({cols});
    }
  }

  edit = media => {
    this.setState({
      isEditing: true,
      selected: media.id,
    });
  }

  add = () => {
    this.setState({
      isEditing: true,
      selected: null,
    });
  }

  remove = mediaId => {
    if(!mediaId) {
      return;
    }
    this.props.removeMedia(mediaId);
  }

  handleSort = ({oldIndex, newIndex}, e) => {
    const media = this.filteredMedia();
    const mediaId = media[oldIndex].id;
    const beforeId = media[newIndex].id;
    this.props.moveTalentMedia(this.props.talentId, mediaId, beforeId);
  }

  filteredMedia() {
    const media = this.props.media;
    const statuses = this.state.statuses;
    const filters = this.state.filters;
    const tags = this.state.tags;
    const division = this.state.division;

    return Object.keys(media)
      .map(id => media[id])
      .filter(media => {
        if(isEmpty(statuses)) return true;
        if(statuses.indexOf('Active') > -1 && !!media.active) {
          return true;
        }
        if(statuses.indexOf('Inactive') > -1 && !media.active) {
          return true;
        }
        return false;
      })
      .filter(media => {
        if(isEmpty(filters)) return true;

        const isImage = media.type === 'image' && media.format !== 'pdf';
        const isDocument = media.type === 'image' && media.format === 'pdf';
        const isVideo = media.type === 'video' || media.type === 'link';


        if(filters.indexOf('Photo') > -1 && isImage) {
          return true;
        }

        if(filters.indexOf('Video') > -1 && isVideo) {
          return true;
        }

        if(filters.indexOf('Document') > -1 && isDocument) {
          return true;
        }

        return false;
      })
      .filter(media => {
        if(isEmpty(tags)) {
          return true;
        }

        const mediaTags = media.tags || [];

        for(var tag of mediaTags) {
          if(tags.indexOf(tag) > -1) {
            return true;
          }
        }

        return false;
      })
      .filter(media => {
        if(!division) {
          return true;
        } 
        return media.divisions?.includes(division);
      })
      .sort((a, b) => {
        let aOrder = getProperty(a, `orderByRole.${this.props.imageOrder}`, null);
        if(aOrder === null) {
          aOrder = getProperty(a, `orderByUser.${this.props.userId}`, null);
        }
        if(aOrder === null) {
          aOrder = a.order || null;
        }
        if(!aOrder === null) {
          aOrder = 0;
        }
        let bOrder = getProperty(b, `orderByRole.${this.props.imageOrder}`, null);
        if(bOrder === null) {
          bOrder = getProperty(b, `orderByUser.${this.props.userId}`, null);
        }
        if(bOrder === null) {
          bOrder = b.order || null;
        }
        if(!aOrder === null) {
          bOrder = 0;
        }

        return aOrder < bOrder ? -1 : 1;
      });
  }

  upgrade = () => {
    this.props.history.push(`/talents/${this.props.talentId}/subscription`);
  }



  renderEmpty() {
    if(!isEmpty(this.props.talent.image || {})) {
      return (
        <div
          className="talent--media-headshot"
          style={{display:'grid', gridTemplateColumns:`repeat(${this.state.cols}, 1fr)`}}
        >
          <MediaItem
            media={{
              active: true,
              approved: true,
              title: "Headshot",
              ...this.props.talent.image
            }}
            canEdit={false}
          />
        </div>
      );
    }

    if(!this.props.isOwnTalent) {
      return (
        <Placeholder
          icon="media"
          message="No Media"
        />
      );
    }

    return (
      <Placeholder
        icon="media"
        message="Add photos, videos, and documents to your media."
        label="Add Media"
        onClick={this.add}
      />
    );
  }

  renderUpgrade() {
    if(!isEmpty(this.props.talent.image || {})) {
      return (
        <div
          className="talent--media-headshot"
          style={{display:'grid', gridTemplateColumns:`repeat(${this.state.cols}, 1fr)`}}
        >
          <MediaItem
            media={{
              active: true,
              approved: true,
              title: "Headshot",
              ...this.props.talent.image
            }}
            canEdit={false}
          />
        </div>
      );
    }

    if(!this.props.isOwnTalent) {
      return (
        <Placeholder
          icon="media"
          message="No Media"
        />
      );
    }

    return (
      <Placeholder
        icon="media"
        message="Please upgrade your account to unlock unlimited media uploads."
        label="Upgrade"
        onClick={this.upgrade}
      />
    );
  }

  renderMedia() {
    // if(!this.props.isPaid) {
    if(!this.props.isPaid || !this.state.isImageEnabled) {
      return this.renderUpgrade();
    }

    if(this.props.media.isLoading) {
      return <Loading/>;
    }

    if(isEmpty(this.props.media)) {
      return this.renderEmpty();
    }

    const media = this.filteredMedia()
      .map((media, i) =>
        <SortableItem
          disabled={!this.props.canEdit || this.state.cols === 1}
          index={i}
          media={media}
          key={media.id}
          onEditPress={this.edit}
          width={mediaSizes[this.state.size]}
          canEdit={this.props.canEdit}
        />
      );

    const listProps = {};
    if(!this.props.useWindowAsScrollContainer) {
      listProps.getContainer = () => getScrollParent(findDOMNode(this));
    }

    return (
      <SortableList
        {...listProps}
        useWindowAsScrollContainer={this.props.useWindowAsScrollContainer}
        // getContainer={(component) => {
        //   let $el = findDOMNode(component);
        //   let $scroller = getScrollParent($el);
        //   return $scroller || document;
        // }}
        cols={this.state.cols}
        axis="xy"
        helperClass="is-dragging"
        pressDelay={isTouchDevice() ? 200 : 0}
        useDragHandle={true}
        onSortEnd={this.handleSort}>
        {media}
      </SortableList>
    );
  }

  renderTitle() {

    let filters = [];
    let actions = [];
    let tabs = [];

    if(this.props.talent.divisions?.length > 0) {
      tabs = this.props.talent.divisions.map(division => {
        return (
          <Tab 
            label={division.charAt(0).toUpperCase() + division.slice(1)} 
            isActive={this.state.division === division}
            onClick={() => this.setDivision(division)}
          />
        )
      });

      let allTab = (
        <Tab 
          label="All" 
          isActive={!this.state.division}
          onClick={() => this.setDivision(null)}
        />
      );

      tabs = [allTab, ...tabs]
    }

    // if(this.props.isPaid) {
    if(this.props.isPaid && (this.state.isImageEnabled || this.state.isVideoEnabled)) {

      if(this.props.canEdit) {
        filters.push(
          <Filter
            label="Active"
            value={this.state.statuses}
            options={['Active', 'Inactive']}
            onChange={statuses => this.setState({statuses})}
            style={{width:115}}
          />
        );
      }

      filters.push(
        <Filter
          label="Type"
          value={this.state.filters}
          options={['Photo', 'Video', 'Document']}
          onChange={filters => this.setState({filters})}
          style={{width:130}}
        />
      );

      let tags = Object.keys(this.props.media || {})
        .map(id => {
          return this.props.media[id].tags;
        })
        .filter(tags => !!tags)
        .reduce((all, tags) => {
          for(var tag of tags) {
            if(all.indexOf(tag) === -1) {
              all.push(tag);
            }
          }
          return all;
        }, []);

      filters.push(
        <Filter
          label="Tags"
          value={this.state.tags}
          options={tags}
          onChange={tags => this.setState({tags})}
        />
      );

      filters.push(
        <Filter
          label="Size"
          value={this.state.size}
          options={['Thumbnail', 'Small', 'Large']}
          onChange={size => this.setState({size}, this.checkSize)}
          multiple={false}
          style={{width:130}}
        />
      );

      if(this.props.canEdit) {
        actions.push(
          <Action onClick={this.add}/>
        );

        actions.push(
          <Action icon="multiupload" onClick={() => this.hiddenInput.click()}/>
        );
      }
    }

    return <Title filters={filters} actions={actions} tabs={tabs}>Media</Title>;
  }

  renderDialog() {
    return (
      <MediaDialog
        talentId={this.props.talentId}
        mediaId={this.state.selected}
        open={this.state.isEditing}
        division={this.state.division}
        onRequestClose={() => this.setState({isEditing:false})}
      />
    );
  }

  render() {
    return (
      <div
        className="talent-media"
        ref={el => this.el = el}>

        {this.renderTitle()}
        {this.renderMedia()}
        {this.renderDialog()}

        <input
          name="imageinput"
          ref={el => this.hiddenInput = el}
          type="file"
          accept={this.state.isVideoEnabled ? mediaFormats.join(',') : imageFormats.join(',')}
          multiple={true}
          onChange={e => {
            this.handleFileSelect(e.target.files);
          }}
          style={{position: 'absolute', left: -1000}}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TalentMedia);